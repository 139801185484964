import Rails from '@rails/ujs'
import { initToggleLoadMoreText } from '../trainings-listing/trainings-listing'
function initFilterbarCategory() {
  var filterbarCategory = document.getElementById('filterbar-category')
  if (filterbarCategory) {
    var levelSelect = document.getElementById('level_id')
    var placeSelect = document.getElementById('place_id')
    if (levelSelect) levelSelect.onchange = (e) => fetchTrainings()
    if (placeSelect) placeSelect.onchange = (e) => fetchTrainings()
  }

  function fetchTrainings() {
    Rails.ajax({
      url: `/components/trainings-listing?level_id=${levelSelect ? levelSelect.value : ''}&place_id=${placeSelect ? placeSelect.value : ''}&category_id=${filterbarCategory.dataset.categoryId}`,
      type: "get",
      success: (data) => {
        document.getElementById('trainings-listing').outerHTML = data.html
        initToggleLoadMoreText()
      }
    })
  }
}

initFilterbarCategory()
