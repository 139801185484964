
var SwiperUne = require('swiper');

new SwiperUne.default('.swiper-container-une', {
      slidesPerView: 1,
      loop: true,
      grabCursor: true,
      autoResize: false,
      centeredSlides: true,
      pagination: {
        el: '.swiper-pagination-une',
        clickable: true,

      },
      breakpoints: {
      768: {
         navigation: {
        nextEl: '.swiper-button-next-une',
        prevEl: '.swiper-button-prev-une',
      }
      }
    }
    });

