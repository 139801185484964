// CSS
import '../stylesheets/application.scss'
import 'swiper/css/swiper.css'
// Autoload Components SCSS
// const scssComponents = require.context('../components', true, /\.scss$/)
// scssComponents.keys().forEach(scssComponents)
import "cocoon-js";

// JS
import Rails from '@rails/ujs';
Rails.start();
require("@rails/activestorage").start()
require("channels")
import 'bootstrap';
import Swiper from 'swiper';
import CountUp from 'countup';


// JS Components autoloading
const jsComponents = require.context('../components', true, /\.js$/)
jsComponents.keys().forEach(jsComponents)

// IMAGES AUTOLOAD (e.g <%= image_pack_tag 'rails.png' %>)
require.context('../images', true)


// ORDER PAGE
$(".btn-payment").click(function () {
  $('.btn-payment input').removeAttr('checked');
  this.children[0].checked = true
});

const submitTraining = document.querySelector('.submit-training')
if (submitTraining) {
  submitTraining.addEventListener('click', (e) => {
    const checkbox = document.querySelector('#display-assistantes');

    const inputAssistantes = document.querySelectorAll('.booking_assistantes_full_name > input');

    const assistanteCheck = () => {
      let filledFields = 0
      inputAssistantes.forEach((ass) => {
        if (ass.value.length != "" ){
          filledFields += 1
        }
      })
      if (filledFields == inputAssistantes.length) {
          return true
      } else {
        return false
      }
    }

    if (checkbox.checked && !assistanteCheck()) {
      e.preventDefault();
      alert("Veuillez remplir le Nom et le Prénom de l'accompagnant(e) ou décocher la case");
    }
  })
}

const contact_form = document.querySelector("#contact_form_message")
if (contact_form) {
  contact_form.onkeyup = (e) => {
    e.target.style.height = "1px";
    console.log(this);
    e.target.style.height = (25 + e.target.scrollHeight) + "px";
  }
}


const required_input = document.querySelectorAll(".required")
if (required_input) {
  window.addEventListener("DOMContentLoaded", (event) => {
    required_input.forEach(required_element =>
      required_element.required = true
    )
  })
}



// TRAINING CHANGE DATE
let dateSelect = document.getElementById('training_date')
if (dateSelect) {
  dateSelect.onchange = (e) => window.location.href = `/formations/${e.target.value}`
}

// BOOKING CHANGE DATE
let dateBookingSelect = document.getElementById('edit_booking_date')
if (dateBookingSelect) {
  dateBookingSelect.onchange = (e) => {
    let editBookingForm = document.getElementById('edit-booking-form')
    Rails.ajax({
      url: `/components/booking_forms/${editBookingForm.dataset.id}/training/${e.target.value}`,
      type: "get",
      success: (data) => { editBookingForm.outerHTML = data.html; }
    })
  }
}
console.log("test");
let assistanteCategory = document.querySelector("#assistante-category");

  if (assistanteCategory) {
    let removeButton = document.querySelectorAll(".assistante_remove");
    let addButton = document.querySelectorAll(".add_assistante");
    const removeHide = () => {

      if (removeButton.length < 2) {
        removeButton[0].style.display = "none"

      } else {
        removeButton[0].style.display = "block"
      }
    }
     removeHide();
      removeButton.forEach((e) => {
        addEventListener('click', removeHide())}
        );
      addButton.forEach((e) => {
        addEventListener('click', removeHide())}
        );
  }

$(document).on('ready', function() {
  $('form').on('click', '.remove_record', function(event) {
    $(this).prev('input[type=hidden]').val('1');
    $(this).closest('tr').hide();
    console.log("test");
    return event.preventDefault();
  });

  $('form').on('click', '.add_fields', function(event) {
    var regexp, time;
    time = new Date().getTime();
    regexp = new RegExp($(this).data('id'), 'g');
    $('.fields').append($(this).data('fields').replace(regexp, time));
    return event.preventDefault();
  });


});


