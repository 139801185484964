function initToggleLoadMoreText(params) {
  $('#collapseLoadMore').on('shown.bs.collapse', () => {
    document.getElementById('load-more').text = "Voir moins"
  })

  $('#collapseLoadMore').on('hidden.bs.collapse	', () => {
    document.getElementById('load-more').text = "Voir plus"
  })

  $('#collapseLoadMore').on('hide.bs.collapse	', () => {
    document.getElementById('trainings-listing').parentElement.scrollIntoView();
  })
}

initToggleLoadMoreText()
export { initToggleLoadMoreText }
