import Rails from '@rails/ujs';

function addlisteners(element) {
  var categorySelect = document.getElementById('category_id')
  categorySelect.onchange = (e) => {
    let levelSelect = document.getElementById('level_id')
    let placeSelect = document.getElementById('place_id')
    Rails.ajax({
      url: `/components/searchbox?category_id=${e.target.value}&level_id=${levelSelect ? levelSelect.value : ''}&place_id=${placeSelect ? placeSelect.value : ''}`,
      type: "get",
      success: (data) => {
        element.outerHTML = data.html;
        initDynamicForm();
      }
    })

  }
}

function initDynamicForm() {
  let searchbox = document.getElementById('searchbox')
  if (searchbox) addlisteners(searchbox)
}
// BOOKING CHANGE DATE
initDynamicForm()
