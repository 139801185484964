
var Swiper = require('swiper');

new Swiper.default('.swiper-container', {
      slidesPerView: 1,
      spaceBetween: 30,
      loop: true,
      grabCursor: true,

      slidesOffsetAfter: 20,
      autoResize: false,
      uniqueNavElements: true,

      pagination: {
        el: '.swiper-pagination',
        clickable: true,

      },
      breakpoints: {
      768: {
        slidesPerView: 2,
        navigation: {
        nextEl: '.swiper-button-next-card',
        prevEl: '.swiper-button-prev-card'
        }
       },
       834: {
        slidesPerView: 3,
        navigation: {
        nextEl: '.swiper-button-next-card',
        prevEl: '.swiper-button-prev-card'
        }
       }
      }
    });

