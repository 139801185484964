var SwiperGallery = require('swiper');

new SwiperGallery.default('.swiper-container-gallery', {
      slidesPerView: 1,
      loop: true,
      grabCursor: true,
      spaceBetween: 10,
      autoResize: true,
      pagination: {
        el: '.swiper-pagination-gallery',
        clickable: true,

      },
      breakpoints: {
      768: {
        slidesPerView: 2,
        navigation: {
          nextEl: '.swiper-button-next-gallery',
          prevEl: '.swiper-button-prev-gallery',
      }
      }
    }
    });
