// Load all the channels within this directory and all subdirectories.
// Channel files must be named *_channel.js.

const channels = require.context('.', true, /_channel\.js$/)
channels.keys().forEach(channels)

console.log("This is the create.js.erb file");
// Render flash message
$('#contact').html("<%= j render 'contact_form/new' %>");
$('#flash-message').html("<%= j render 'contact_form/flash' %>").delay(3000).fadeOut(4000);

