var CountUp = require('countup');

// const isInViewport = (elem) => {
//     let bounding = elem.getBoundingClientRect();
//     return (
//         bounding.top >= 0 &&
//         bounding.left >= 0 &&
//         bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
//         bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
//     );
// };

// var unViewed = true;
// console.log(unViewed);

var options = {
  useEasing: true,
  useGrouping: true,
};

var statistics = $(".number");
// var chiffres = document.querySelector('#chiffres');
// window.addEventListener('scroll', () => {
//   if (isInViewport(chiffres) || unViewed == true) {
//     let unViewed = false
    statistics.each(function(index) {
  // Find the value we want to animate (what lives inside the p tags)
  var value = $(statistics[index]).html();
  // Start animating
  var statisticAnimation = new CountUp(statistics[index], 0, value, 0, 10, options);
  statisticAnimation.start();

});
//   }
// });

